import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
// import { isIOS } from 'react-device-detect';
import { Container, Content } from '../../components/containers/Container';
import Heading from '../../components/heading/Heading';
import SubHeading from '../../components/heading/SubHeading';
import Para from '../../components/para/Para';
import VideoIframe from '../../components/block/VideoIframe';
import HeroImage from '../../components/block/HeroImage';
import BlockSpinner from '../../components/spinner/BlockSpinner';
import WistiaEmbed from '../../components/WistiaEmbed';
import Footer from '../../components/footer/Footer';

import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';

const Explanation = () => {
  const {explanation, status } = useSelector(state => state.root);
  const [screenState, setScreenState] = useState(null);
 
  // const isAndroid11 = window.localStorage.getItem("isAndroid11");
  // const IOS = isIOS; 

  const headline = screenState && screenState.headline;
  const subheadline = screenState && screenState.subheadline;
  const introText = screenState && screenState.text;
  const heroImage = screenState && screenState.heroImage.src;
  const heroVideo = screenState && screenState.video.src;
  const heroPriority = screenState && screenState.heroPriority;
  const videoPlayer = screenState && screenState.video?.player;
  const btnText = screenState && screenState.btn.text;
  const nextBtnPath = screenState && screenState.btn.path;

  const nextPath =  nextBtnPath ? nextBtnPath : '/';
  // const [nextScreen, setNextScreen] = useState('');
  // const isIos_isAndrooid = IOS || isAndroid11 === 'true';

  // const iosOrAndroid11 =  (IOS || isAndroid11);

  // if ((IOS && isAndroid11)) {
  //   console.log('first....');
  //   console.log('value of IOS', IOS)
  //   console.log('value of isAndroid11', isAndroid11)
  // } else {
  //   console.log('last...')
  //   console.log('value of IOS', IOS)
  //   console.log('value of isAndroid11', isAndroid11)
  // }


  let showHero;

  if (heroPriority === 'video') {
    if (videoPlayer === 'wistia') {
      showHero = (<div style={{ marginBottom: 25 }}><WistiaEmbed hashedId={heroVideo} /></div>)
    } else if (videoPlayer === 'Bunny') {
      showHero = (<VideoIframe title="welcome" src={heroVideo} mb={25} />)
    } else {
      showHero = (<VideoIframe title="welcome" src={heroVideo} mb={25} />)
    }
  }

  if (heroPriority === 'image') {
    showHero = (<HeroImage src={heroImage} mb={15} alt="welcome hero"/>)
  }

  const isMounted = useIsMounted();

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setScreenState(explanation);
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });

      // if (isIos_isAndrooid) {
      //   setNextScreen('/steps0');
      // } else {
      //   setNextScreen(nextPath);
      // }
    }
  }, [isMounted, status, explanation ]);

  return (
    <>
      <Container>
        <Content heightCalc>
          {
            status === 'loading' ?
            <BlockSpinner /> : 
            <>
              <Heading
                align="center"
                mt={20}
                mb={20}
              >
                {headline}
              </Heading>

              <SubHeading
                align="center"
                mb={20}
              >
                {subheadline}
              </SubHeading>

              {showHero}

              <Para mb={20}>{introText && generateParagraph(introText)}</Para> 

              <div style={{ paddingBottom: 30}}>
                <Link to={nextPath} className="sa-btn sa-btn-block">{btnText}</Link>
              </div>

              <Footer sectionName="explanation" />
            </>
          }
        </Content>
      </Container>
    </>
  )
}

export default Explanation;
