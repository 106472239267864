import { useState, useEffect, useCallback } from 'react';

const ProcessStream = (ref, audioValue, videoValue, streamErrorCallBack, streamValue) => {

  const notSupport = !navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices;
  const currentRef = ref.current;

  function getDevices() {
    return navigator.mediaDevices.enumerateDevices();
  }
  
  const [cntr, setCntr] = useState({
    audio: { 
      deviceId: audioValue ? { exact: audioValue} : undefined
    },
    video : {
      deviceId: videoValue ? { exact: videoValue} : undefined,
      // facingMode: { exact: "user" },
      // width: { ideal: 1280},
      // height: {  ideal: 720},
    }
  });

  function handleError(error) {
    console.error('Error happen: ', error);
  }

  const getStream = useCallback(async () => {

    window.localStorage.setItem('constraints', JSON.stringify(cntr));

    console.log(cntr)

    if (window.stream) {
      window.stream.getTracks().forEach(track => {
        window.stream.removeTrack(track);
        track.stop();
      });
    }

    return await navigator.mediaDevices.getUserMedia(cntr).then((stream) => {
      window.stream = stream; 
      // stream.getTracks().forEach(track => {
      //   stream.removeTrack(track);
      //   track.stop();
      // });
    
      if (currentRef) {
        currentRef.srcObject = stream;
      }

      if (streamValue) {
        stream.getTracks().forEach(track => {
          stream.removeTrack(track);
          track.stop();
        });
      }
     
    }).catch(handleError);

  }, [currentRef, cntr, streamValue]);

  // [audioValue, videoValue, currentRef, streamValue]

  useEffect(() => {
    setCntr({
      audio: { 
        deviceId: audioValue ? { exact: audioValue} : undefined
      },
      video : {
        deviceId: videoValue ? { exact: videoValue} : undefined,
        // facingMode: { exact: "user" },
        // width: { ideal: 1280},
        // height: {  ideal: 720},
      }
    })
  }, [audioValue, videoValue ]);
 
  useEffect(() => {
    (async () => { 
      if (notSupport) {
        streamErrorCallBack();
        return;
      } else {
        console.log('run...')
        getStream().then(getDevices);
      }
    })();
  }, [notSupport, getStream, streamErrorCallBack]);
}

export default ProcessStream;