import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';

import { Container, Content } from '../../components/containers/Container';
import Heading from '../../components/heading/Heading';
import SubHeading from '../../components/heading/SubHeading';
import Para from '../../components/para/Para';
import VideoIframe from '../../components/block/VideoIframe';
import HeroImage from '../../components/block/HeroImage';
import BlockSpinner from '../../components/spinner/BlockSpinner';
import WistiaEmbed from '../../components/WistiaEmbed';
import Footer from '../../components/footer/Footer';

import { useIsMounted } from '../../hooks/useIsMounted';
import { generateParagraph } from '../../utils/generateParagraph';

const Welcome = () => {
  const {welcome, status } = useSelector(state => state.root);
  const [welcomeState, setEelcomeState] = useState(null);
  
  const headline = welcomeState && welcomeState.headline;
  const subheadline = welcomeState && welcomeState.subheadline;
  const welcomeText = welcomeState && welcomeState.text;
  const heroImage = welcomeState && welcomeState.heroImage.src;
  const heroVideo = welcomeState && welcomeState.video.src;
  const heroPriority = welcomeState && welcomeState.heroPriority;
  const videoPlayer = welcomeState && welcomeState.video?.player;
 
  const nextBtnText = welcomeState && welcomeState.btn.text;
  const nextBtnPath = welcomeState && welcomeState.btn.path;
  const nextPath =  nextBtnPath ? nextBtnPath : '/';

  // const heroVideo = 'https://iframe.mediadelivery.net/embed/1367/2ed29e9b-4d84-4e37-8be8-a781bee05626';
  // const videoPlayer = 'Bunny';

  let showHero;

  if (heroPriority === 'video') {
    if (videoPlayer === 'wistia') {
      showHero = (<div style={{ marginBottom: 25 }}><WistiaEmbed hashedId={heroVideo} /></div>)
    } else if (videoPlayer === 'Bunny') {
      showHero = (<VideoIframe title="welcome" src={heroVideo} mb={25} />)
    } else {
      showHero = (<VideoIframe title="welcome" src={heroVideo} mb={25} />)
    }
  }

  if (heroPriority === 'image') {
    showHero = (<HeroImage src={heroImage} mb={15} alt="welcome hero"/>)
  }

  const isMounted = useIsMounted();

  useEffect(() => {
    if (status === 'success' && isMounted.current) {
      setEelcomeState(welcome);
      window.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [isMounted, status, welcome]);

  return (
    <>
      <Container>
        <Content heightCalc>
          {
            status === 'loading' ?
            <BlockSpinner /> : 
            <>
              <Heading
                align="center"
                mt={20}
                mb={20}
              >
                {headline}
              </Heading>

              {showHero}
              <SubHeading align="center" mb={20}>{subheadline}</SubHeading>
              <Para mb={30}>{welcomeText && generateParagraph(welcomeText)}</Para>
              <div style={{ paddingBottom: 30 }}>
                <Link to={nextPath} className="sa-btn sa-btn-block">{nextBtnText}</Link>
              </div>
              <Footer sectionName="welcome" />
            </>
          }
        </Content>
      </Container>
    </>
  )
}

export default Welcome;